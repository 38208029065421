import React from 'react';
import './styles/global.css'
import './styles/pages/landing.css'

import logoImg from './images/logo.svg'
import instagramImg from './images/instagram.svg'
import whatsAppImg from './images/whatsapp.svg'
import facebookImg from './images/facebook.svg'

function App() {
  return (
    <div id="page-landing">
      <div className="content-wrapper">
        <img src={logoImg} alt="" width="400px" />

        <div className="content-text">
          <span>Em breve um portal cheio de novidades.</span><br />
          <span>Enquanto isso, visite nossas redes sociais e entre em contato através de nosso whatsapp.</span>          
        </div>

        <div className="social-media">
          <a href="https://www.instagram.com/festeggiando.festasedecoracoes/?hl=pt-br" target="_blank" rel="noreferrer"><img src={instagramImg} alt="" width="30px" /></a>
          &nbsp;
          <a href="https://www.facebook.com/festeggiando.festasedecoracoes" target="_blank" rel="noreferrer"><img src={facebookImg} alt="" width="30px" /></a>
          &nbsp;
          <a href="https://wa.me/5527998392425?lang=pt_br" target="_blank" rel="noreferrer"><img src={whatsAppImg} alt="" width="30px" /></a>
        </div>

        <div className="location">
          <strong>100% CAPIXABA</strong>&nbsp;|&nbsp;
          <span>Desenvolvido em Vitória - Espírito Santo</span>
        </div>
      </div>
    </div>
  );
}

export default App;